import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import HorseButton from "../components/horse-button"

const IndexPage = () => (
  <Layout bodyClass="home" hideHeader="true">
    <Seo title="Look at This Horse | The Stables" />
    <h1>
      welcome to <br />
      <span>lookatthis.horse</span>
    </h1>

    <pre className="ascii-art">
      <code>{`
                                 |⎞    /|
                              ___| ⎞,,/_/
                           ---__/ ⎞/    ↖︎
                          __--/     (H)  ↖︎
                          _ -/    (_      ↖︎
        _____            - -/       ⎝_  /--↖︎
     __-------___--___--/ -         / ⎝  o r)
    /                                 /⎝___/
   /                                 /
  ||          )                    _/ ⎞
  ||         /              _     ⎝   |
  | |      /--______      ___s    /⎝  e
  | /   __-   _/    -----    |  |    ↖︎ ↖︎
   |   -  ⎞   /              | |      ⎞ )
   |  |    | |               | )      | |
    | |    | |               | |     / /
    | |   <  |               | |    |_/
   <  |   /__⎞              <  ⎞
   /__⎞                     /___⎞
`}</code>
    </pre>

    <p>find horse button. press for horse.</p>
    <code>
      <pre>
        -----------
        <br />
        <HorseButton />
        <br />
        -----------
      </pre>
    </code>
  </Layout>
)

export default IndexPage
